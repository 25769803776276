$primary-color: #3BB406;
$secondary-color: #365db5;

$icon-color:$secondary-color;
$header-background:#FFF;

$page-header-bkg:#f0fff0;
$page-header:$secondary-color;

$toggle-icon-bar:white;
$line-color:#ededed;

$home-search-bkg:#fef8e1;
$home-search-text:$primary-color;
$home-search-submit-bkg:$primary-color;
$home-search-submit-txt:#FFF;


/* header and navbar */
header{
  background-color: $header-background;
  border-bottom: 10px solid $primary-color;
}

.navbar{
  margin-bottom:0;
  min-height:90px;
}
.navbar-brand {
  padding: 10px;
  img {
    max-width:160px;
  }
}
@media screen and (max-width: 1000px){
  .navbar-brand img{
    max-width:160px;
  }
}
@media screen and (max-width: 430px) {
  .navbar-brand img {
    max-width:140px;
  }
  .navbar {
    min-height:70px;
  }
}
.navbar-nav>li>a {
  padding-top:35px;
  color: $secondary-color;
  text-transform:uppercase !important;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color : transparent;
}
.navbar-toggle {
  background-color:$primary-color;
  margin-right:0;

  .icon-bar {
    background-color: $toggle-icon-bar;
  }
}

/* body, main and basic styles */
body{
  color:#666;
  font:16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}
a{
  color:$secondary-color;
}
a:hover, a:active{
  color:$primary-color;
}
h1, h2, h3, h4, h5, h6{
  color : $secondary-color;
}
ul{
  list-style: none;
  -webkit-padding-start:0;
}
p {
  margin:0 0 20px;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
input[type="reset"], input[type="button"], input[type="submit"]{
  background-color: $primary-color;
  border:1px solid $primary-color;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
main {
  padding:30px 0 40px;
}
/* blog posts */
.post {
  padding: 0 20px 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid $line-color;
}

.meta {
  padding:0;
  margin-bottom :5px;
  font-size:13px;
  color: $secondary-color;
  i {
    color:$icon-color;
  }
}
.author, .date {
  padding:0 10px 0 5px;
}
p.title {
  font-size: 22px;
  line-height: 30px;
  margin-bottom :0;
  padding-bottom:10px;
  color:#1A1A1A;
  text-transform: uppercase;
  a{
    color:#1A1A1A;
  }
}
.post-excerpt{
  padding: 0 0 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid $line-color;
  .icon{
    display:inline-block;
    float:left;
    width:10%;
    font-size:4rem;
    margin-right: 2%;
    text-align:center;
    img{
      max-width:60px;
    }
  }
  .excerpt{
    display:inline-block;
    width:88%;
  }
  a.button{
    font-weight: bold;
    text-transform: uppercase;
    color : $primary-color;
    border-bottom: 1px solid $primary-color;
    letter-spacing:.1em;
  }
}
.pagination {
  display: block;
  min-height: 50px;
  border-bottom: 1px solid $line-color;

  a {
    color: $secondary-color;
    border-bottom: 1px solid $secondary-color;
    letter-spacing: .1em;
  }
  a.right {
    display: inline-block;
    float: right;
  }
  a.left {
    display: inline-block;
    float: left;
  }
  a:hover {
    text-decoration: none;
    font-weight: bold;
  }

}
/* sidebar */
aside h3.widget-title, aside h3, aside h2.title {
  font-size: 20px;
  line-height: 23px;
  padding: 10px 15px;
  margin-bottom:20px;
  font-weight: normal;
  margin-top:0;
  color:#FFF;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 4px solid $secondary-color;
  background-color:$primary-color;
}
aside .textwidget ul{
  padding: 0 10px 10px 10px;
  margin-bottom:20px;
  li:before{
    font-family:'FontAwesome';
    content:'\f105';
    color:orange;
    width:15px;
    padding-right: 10px;
  }
  li{
    margin: 6px 0;
    padding-left: 15px;
    a{
      color: #505050;
      line-height: 1.4;
    }
  }
}
/* footer */
footer{
  background-color:#f9f9f9;
  padding: 10px 0 10px;
  color:$secondary-color;
  font-size: 14px;
  line-height: 21px;
  text-align:center;
  .container{
    margin-left: auto;
    max-width: 85%;
  }
  a {
    display:none
  }
  .copyright {
    text-align:center;
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;

  a {
    display:block;
    background-color: $primary-color;
    color: #FFFFFF;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
@media screen and (max-width:830px) {
  footer .copyright{
    padding-bottom: 10px;
    display: block;
  }
}
/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: $page-header-bkg !important;
  padding               : 20px 0 30px;
  text-align            : center;
  background-attachment : fixed;
  background-position   : center center;
  background-size       : cover;
  margin-top            : -1px;
  text-transform: uppercase;
  h1.page-title {
    font-size: 30px;
    line-height: 37px;
    color: $page-header;
    font-weight: 700;
    margin:0 auto;
  }
}
/* only needed when using the signup-message partial */
#home-intro{
  height:380px;
  text-align:center;
  background-color:#f0fff0;
  margin:-20px auto 40px;
  border-bottom: 10px solid $primary-color;
  .promotional_bar_content{
    text-align:left;
    height:360px;
    background-image: url(/images/jumbotron-image.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    max-width: 1170px;
    margin: auto;
    background-size:contain;
    h3{
      padding-top:70px;
      text-transform:uppercase;
      padding-bottom:10px;
      color: $secondary-color;
      font-size:3.4em;
    }
    p{
      font-size:2em;
      margin: 30px auto;
      padding-bottom:10px;
    }
    a{
      background-color: $primary-color;
      border: 1px solid $primary-color;
      color: white;
      padding: 10px 20px;
      border-radius: 3px;
      margin-top:20px;
      font-size: 1.3em;
    }
    @media screen and (max-width:1075px){
      background-position:center bottom;
      h3, p, a{
        display:none;
      }
    }
  }
}


/*by-sector, sectors only needed when including sector-module partial in sidebar
by-location, locations only needed when including city-module partial in sidebar*/
aside .by-sector h3, aside .by-location h3, {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  font-weight: normal;
  margin-top: 0;
  color: #333;
  letter-spacing: 2px;
  text-transform: uppercase;
}
aside ul.sectors li, aside ul.locations li {
  flex-basis: 100%;
  padding-bottom:5px;
  a i{
    color: $icon-color !important;
  }
}

/* site specific before_footer*/
.before-footer{
  background-color:#f9f9f9;
  color:#333;
  padding:30px 0 10px;
  font-size:12px;
  text-align: center;
  margin:auto;
  img{
    max-width:120px;
    display: block;
    text-align: center;
    margin: 5px auto 10px;
  }
  a{
    color:#333;
    font-weight:400;
    font-size:13px;
    padding:5px;
  }

}

/*sidebar listings*/
.jobs {
  padding: 0;
  h2.Title {
    overflow: hidden;
    margin-bottom:5px;
  }
}

/* home_features */
.home-features {
  margin:65px auto 45px;
  .features-link {
    text-align: center;
    line-height: 1.1;
    display: block;
    position: relative;
    border-top-width: 8px;
    border-bottom-width: 3px;
    border-left-width: 0;
    border-right-width: 0;
    border-style: solid;
    .icon {
      width: 104px;
      height: 60px;
      display: block;
      position: absolute;
      top: -30px;
      left: 50%;
      margin-left: -52px;
      z-index: 13;
      background-color: #f6f6f6;
      opacity: .75;
      img.default{
        width: 64px;
        height: 64px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -32px 0 0 -32px;
        z-index: 1;
      }
      img.hover{
        display:none;
        width: 64px;
        height: 64px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -32px 0 0 -32px;
        z-index: 1;
      }
    }
    .icon:before, .icon:after{
      border-color: #f6f6f6;
      content: ' ';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      margin-left: -52px;
      border-left-width: 52px;
      border-right-width: 52px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-style: solid;
    }
    .icon:before{
      border-bottom-width: 30px;
      border-top: 0 none !important;
      top: -30px;
    }
    .icon:after{
      border-top-width: 30px;
      border-bottom: 0 none !important;
      bottom: -30px;
    }
    img.fullwidth {
      margin-bottom: 10px;
      display: block;
      width: 100%;
    }
    .features-title {
      display: block;
      font-size: 20px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .inner {
      display: block;
      padding: 0 15px 20px 15px;
    }
  }
  .features-link:hover{
    text-decoration: none !important;
    img.default{
      display:none;
    }
    img.hover{
      display:block;
    }
    .icon {
      background-color:$primary-color;
    }
    .icon:before, .icon:after {
      border-color:$primary-color;
    }
  }
  .features-link:before{
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    bottom: -3px;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top: 0 none;
    border-color: $secondary-color;
  }
  .features-link:hover:before {
    border-color:$primary-color;
  }
}

/* popular searches */
.popular-searches{
  margin:50px auto 80px;
  .header h4{
    font-size: 26px;
    color:#505050;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 13px;
    margin-bottom: 20px;
    position: relative;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-color:$primary-color;
  }
  .header h4:after{
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    bottom: -12px;
    left: 50%;
    margin-left: -12px;
    border-width: 12px;
    border-style: solid;
    border-color:$primary-color;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom: 0 none;
  }
  .popular-item a img{
    border:2px solid transparent;
  }
  .popular-item a:hover img{
    border:2px solid $primary-color;
  }

}

/* home listings */
.home-jobs{
  h4.header {
    font-size: 26px;
    color:#505050;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 13px;
    margin-bottom: 20px;
    position: relative;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-color:$primary-color;
  }
  h4.header:after{
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    bottom: -12px;
    left: 50%;
    margin-left: -12px;
    border-width: 12px;
    border-style: solid;
    border-color:$primary-color;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom: 0 none;
  }
  section{
    margin-bottom:20px;
    border-bottom:1px solid $line-color;
  }
  h2.Title {
    font-size: 22px;
    max-height:26px;
    text-transform: uppercase;
    margin-bottom: 5px;
    a {
      color: #1A1A1A;
    }
  }
  a.Company{
    color: $secondary-color;
  }
  .TimePosted {
    display: block;
    color:#999;
    font-size:11px;
  }
  .snippet {
    display: block;
    margin-bottom:20px;
    a{
      color: $primary-color;
    }
  }
}